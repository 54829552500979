<template>
  <el-dialog
    :title="'新增'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
    <el-form-item label="字典项键" prop="itemKey">
      <el-input v-model="dataForm.itemKey" placeholder="字典项文本" clearable></el-input>
    </el-form-item>
    <el-form-item label="字典项值" prop="itemValue">
      <el-input v-model="dataForm.itemValue" placeholder="字典项值" clearable></el-input>
    </el-form-item>
    <el-form-item label="描述" prop="description">
      <el-input v-model="dataForm.description" placeholder="描述" clearable type="textarea" autosize></el-input>
    </el-form-item>
    <el-form-item label="排序" prop="sort">
      <el-input-number v-model="dataForm.sort" controls-position="right" :min="1" :max="10"></el-input-number>

    </el-form-item>
    <el-form-item label="状态" prop="status">
      <el-switch
        active-value=1
        inactive-value=0
        v-model="dataForm.status"
        active-color="#13ce66"
        inactive-color="#ff4949">
      </el-switch>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            dataForm: {
                id: 0,
                dictId: '',
                itemKey: '',
                itemValue: '',
                description: '',
                sort: '',
                status: 1
            },
            dataRule: {
                dictId: [
                    {required: true, message: '字典id不能为空', trigger: 'blur'}
                ],
                itemKey: [
                    {required: true, message: '字典项文本不能为空', trigger: 'blur'}
                ],
                itemValue: [
                    {required: true, message: '字典项值不能为空', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '状态不能为空', trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        init(id) {
            this.dataForm.dictId = id;
            this.visible = true;
            this.$refs.dataForm.resetFields();
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = this.dataForm.id ? this.$client.updateDictItem : this.$client.saveDictItem;
                    submit({
                        id: this.dataForm.id,
                        dictId: this.dataForm.dictId,
                        itemKey: this.dataForm.itemKey,
                        itemValue: this.dataForm.itemValue,
                        description: this.dataForm.description,
                        sort: this.dataForm.sort,
                        status: this.dataForm.status
                    }).then((data) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$emit('refreshDataList');
                                }
                            });
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        }
    }
};
</script>
