<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()" label-width="120px">
    <el-form-item label="字典名称" prop="dictName">
      <el-input v-model="dataForm.dictName" placeholder="字典名称" clearable></el-input>
    </el-form-item>
    <el-form-item label="字典编码" prop="dictCode">
      <el-input v-model="dataForm.dictCode" placeholder="字典编码" clearable></el-input>
    </el-form-item>
    <el-form-item label="描述" prop="description">
      <el-input v-model="dataForm.description" placeholder="描述"  type="textarea" autosize clearable></el-input>
    </el-form-item>
    <el-form-item label="字典类型" prop="type">
      <el-radio v-model="dataForm.type" :label=0>string</el-radio>
      <el-radio v-model="dataForm.type" :label=1>number</el-radio>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            dataForm: {
                id: 0,
                dictName: '',
                dictCode: '',
                description: '',
                delFlag: '',
                createBy: '',
                createTime: '',
                updateBy: '',
                updateTime: '',
                type: 0
            },
            dataRule: {
                dictName: [
                    {required: true, message: '字典名称不能为空', trigger: 'blur'}
                ],
                dictCode: [
                    {required: true, message: '字典编码不能为空', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '字典类型0为string,1为number不能为空', trigger: 'blur'}
                ]
            }
        };
    },
    methods: {
        init(id) {
            this.dataForm.id = id || 0;
            this.visible = true;
            this.$nextTick(() => {
                this.$refs.dataForm.resetFields();
                if (this.dataForm.id) {
                    this.$client.getDictById(this.dataForm.id).then((data) => {
                        if (data && data.code === 0) {
                            this.dataForm.dictName = data.dict.dictName;
                            this.dataForm.dictCode = data.dict.dictCode;
                            this.dataForm.description = data.dict.description;
                            this.dataForm.delFlag = data.dict.delFlag;
                            this.dataForm.createBy = data.dict.createBy;
                            this.dataForm.createTime = data.dict.createTime;
                            this.dataForm.updateBy = data.dict.updateBy;
                            this.dataForm.updateTime = data.dict.updateTime;
                            this.dataForm.type = data.dict.type;
                        }
                    });
                }
            });
        },
        // 表单提交
        dataFormSubmit() {
            this.$refs.dataForm.validate((valid) => {
                if (valid) {
                    const submit = this.dataForm.id ? this.$client.updateDict : this.$client.saveDict;
                    submit({
                        id: this.dataForm.id || undefined,
                        dictName: this.dataForm.dictName,
                        dictCode: this.dataForm.dictCode,
                        description: this.dataForm.description,
                        type: this.dataForm.type
                    }).then((data) => {
                        if (data && data.code === 0) {
                            this.$message({
                                message: '操作成功',
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$emit('refreshDataList');
                                }
                            });
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        }
    }
};
</script>
