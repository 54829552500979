<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="名称/编号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button  type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button  type="danger" @click="deleteHandle()" :disabled="dataListSelections.length <= 0">批量删除</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      v-loading="dataListLoading"
      @selection-change="selectionChangeHandle"
      style="width: 100%;">
      <el-table-column
        type="selection"
        header-align="center"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="dictName"
        header-align="center"
        align="center"
        label="字典名称">
      </el-table-column>
      <el-table-column
        prop="dictCode"
        header-align="center"
        align="center"
        label="字典编码">
      </el-table-column>
      <el-table-column
        prop="description"
        header-align="center"
        align="center"
        label="描述">
      </el-table-column>
      <el-table-column
        prop="createBy"
        header-align="center"
        align="center"
        label="创建人">
      </el-table-column>
      <el-table-column
        prop="createTime"
        header-align="center"
        align="center"
        label="创建时间">
      </el-table-column>
      <el-table-column
        prop="updateBy"
        header-align="center"
        align="center"
        label="更新人">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        header-align="center"
        align="center"
        label="更新时间">
      </el-table-column>
      <el-table-column
        prop="type"
        header-align="center"
        align="center"
        label="字典类型">
        <template slot-scope="scope">
          <div v-if="scope.row.type === 0">string</div>
          <div v-if="scope.row.type === 1">number</div>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="220"
        label="操作">
        <template slot-scope="scope">
            <el-button type="primary" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
            <el-button type="primary" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
          <el-popover
            placement="right"
            trigger="click">
            <el-button type="primary" @click="addItemHandle(scope.row.id)">新增</el-button>
            <el-table :data="gridData">
                <el-table-column
                    prop="sort"
                    header-align="center"
                    align="center"
                    label="排序">
                </el-table-column>
              <el-table-column
                prop="itemKey"
                header-align="center"
                align="center"
                width="200"
                label="字典项文本">
              </el-table-column>
              <el-table-column
                prop="itemValue"
                header-align="center"
                align="center"
                width="150"
                label="字典项值">
              </el-table-column>
              <el-table-column
                prop="description"
                header-align="center"
                align="center"
                width="160"
                label="描述">
              </el-table-column>
              <el-table-column
                prop="status"
                header-align="center"
                align="center"
                label="状态">
                <template slot-scope="scope">
                  <div v-if="scope.row.status === 1">启用</div>
                  <div v-else-if="scope.row.status === 0">禁用</div>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                header-align="center"
                align="center"
                width="160"
                label="操作">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="updateItemHandle(scope.row.id)">修改</el-button>
                  <el-button type="primary" size="small" @click="deleteItemHandle(scope.row.id)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button  size="small" slot="reference" @click="itemHandle(scope.row.id)">子项</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
    background
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <add-item  v-if="addOrUpdateVisible" ref="addItem" @refreshDataList="getDataList" ></add-item>
    <update-item v-if="addOrUpdateVisible" ref="updateItem" @refreshDataList="getDataList"></update-item>
  </div>
</template>

<style>
  .el-table .disable-row {
    background: oldlace;
  }
</style>

<script>
import AddOrUpdate from './dict-add-or-update';
import AddItem from './dictItem-add';
import UpdateItem from './dictItem-update';
export default {
    data() {
        return {
            dataForm: {
                key: ''
            },
            gridData: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            totalPage: 0,
            dataListLoading: false,
            dataListSelections: [],
            addOrUpdateVisible: false
        };
    },
    components: {
        AddOrUpdate,
        AddItem,
        UpdateItem
    },
    created() {
        this.getDataList();
    },
    methods: {
        // 获取数据列表
        getDataList() {
            this.dataListLoading = true;
            this.$client.getDictList({
                current: this.pageIndex,
                size: this.pageSize,
                key: this.dataForm.key
            }).then((data) => {
                if (data && data.code === 0) {
                    this.dataList = data.data.records;
                    this.totalPage = data.data.total;
                } else {
                    this.dataList = [];
                    this.totalPage = 0;
                }
                this.dataListLoading = false;
            });
        },
        // 每页数
        sizeChangeHandle(val) {
            this.pageSize = val;
            this.pageIndex = 1;
            this.getDataList();
        },
        // 当前页
        currentChangeHandle(val) {
            this.pageIndex = val;
            this.getDataList();
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;
        },
        // 新增 / 修改
        addOrUpdateHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addOrUpdate.init(id);
            });
        },
        addItemHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.addItem.init(id);
            });
        },
        updateItemHandle(id) {
            this.addOrUpdateVisible = true;
            this.$nextTick(() => {
                this.$refs.updateItem.init(id);
            });
        },
        itemHandle(id) {
            this.$client.getDictItemListById(id).then((data) => {
                if (data && data.code === 0) {
                    this.gridData = data.dictItem;
                } else {
                    this.dataList = [];
                }
            });
        },
        deleteItemHandle(id) {
            this.$confirm(`确定对id=${id}操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delDictItem(id).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList();
                            }
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        },
        // 删除
        deleteHandle(id) {
            const ids = id ? [id] : this.dataListSelections.map(item => {
                return item.id;
            });
            this.$confirm(`确定对[id=${ids.join(',')}]进行[${id ? '删除' : '批量删除'}]操作?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.delDict(ids).then((data) => {
                    if (data && data.code === 0) {
                        this.$message({
                            message: '操作成功',
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.getDataList();
                            }
                        });
                    } else {
                        this.$message.error(data.msg);
                    }
                });
            });
        }
    }
};
</script>
